<template>
  <div class="about-box">
    <div class="cover">
      <img
        src="https://oss.pigwan.net/wapi/upload/images/2022/01/about-1.jpg"
        alt=""
      />
    </div>
    <div class="description">
      <p>
        卓越奋斗换取未来
        <br />
        打造绿色电网，节能减排
        <br />
        为客户提供优质产品，实现自我价值
      </p>
    </div>
    <div class="picture">
      <img
        src="https://oss.pigwan.net/wapi/upload/images/2022/01/about-2.jpg"
        alt=""
      />
    </div>
    <div class="description-two">
      <p>
        公司恪守"许继绿能"品牌战略
        <br />
        始终以"让我们的客户充分享受高可靠性 、高科技的装备"为使命
        <br />
        致力于打造客户满意，员工自豪的利益共同体
      </p>
    </div>

    <div class="picture-big">
      <img src="https://oss.pigwan.net/wapi/upload/images/2022/01/about-3.jpg" alt="">
      <div class="picture-info">
        <h2>愉悦的办公环境</h2>
        <p>不断改善员工工作条件，创造优越的劳动条件，提供舒适的办公环境，确保员工身心健康。</p>
        <p>公司配备了冷暖中央空调。</p>
        <p>寒冷的冬日，窗外虽然是寒风凛冽，办公室内，却一片春意盎然；</p>
        <p>酷暑的夏日，窗外虽然是烈日炎炎，办公室内，却依然凉风阵阵。</p>
      </div>
    </div>
    <div class="description">
      <p>我们相信，我们的用户和我们一样——追求技术创新，享受美而智能的设计，向往自由无忧的旅程，并憧憬全球化的社区。</p>
    </div>
    <div class="three-box">

      <div class="box-item" id="contactUs">
        <img src="https://oss.pigwan.net/wapi/upload/images/2022/01/contact-us.jpg" alt="联系我们 - 关于许继绿能 - 河南许继绿能官网"
             class="">
        <h4>联系我们</h4>
        <p>
          地址 / 河南自贸试验区郑州片区（郑东）金水东路心怡路交叉口雅宝东方国际广场2号楼 <br>
          邮箱 / hnxjln@163.com <br>
          电话 / 0374-8097869 / 18836160037(微信同号)
        </p>
      </div>

      <div class="box-item">
        <img src="https://oss.pigwan.net/wapi/upload/images/2022/01/download.jpg" alt="联系我们 - 关于许继绿能 - 河南许继绿能官网"
             class="">
        <h4>下载中心</h4>
        <p>
          用户需要产品的说明书请联系售后<br>
          电话：0374-8097869<br>
        </p>
      </div>

      <div class="box-item">
        <img src="https://oss.pigwan.net/wapi/upload/images/2022/01/join-us.jpg" alt="联系我们 - 关于许继绿能 - 河南许继绿能官网"
             class="">
        <h4>加入我们</h4>
        <p>
          公司拥有优秀技术研发团队和管理团队，具有良好的工作环境及和谐向上的工作氛围，公司成立时间虽短，但以优质的产品及服务赢得了客户的一致好评与青睐 <br>
          河南许继绿能科技有限公司一向注重优秀人才培养与引进，为企业快速发展储备各类人才，以此来促进企业与员工的共同成长 <br>
          在这里有你施展才华的舞台，在这里你不会虚度光阴，在这里你会体会到工作的快乐，欢迎优秀的您加入我们许继绿能！<br>
          <a href="">点击查看招聘岗位</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import state from "../../store/state";

export default {
  name: "About",
  components: {},
  mounted() {
    document.title = "关于我们 - "+state.websiteSet.website.title
  }
};
</script>

<style scoped>
.about-box {
  width: 100%;
}

.cover {
  width: 100%;
}

.cover img {
  width: 100%;
}

.description {
  text-align: center;
  font-size: 24px;
  line-height: 48px;
  width: 680px;
  margin: 4vw auto 0;
  font-weight: lighter;
}

.picture {
  width: 62.5vw;
  margin: 4vw auto;
}

.picture img {
  width: 100%;
  object-fit: cover;
}

.description-two {
  text-align: center;
  font-weight: lighter;
}

.picture-big {
  margin: 4vw auto;
  position: relative;
}

.picture-big img {
  width: 100%;
}

.picture-info {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  padding: 0;
}

.picture-info h2 {
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #FFFFFF;
  font-weight: lighter;

}

.picture-info p {
  width: 700px;
  margin: 1.5vw auto 0;
  text-align: center;
  color: #FFFFFF;
  font-weight: lighter;
  line-height: 0px;
}

.three-box {
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-bottom: 4vw;
}

.box-item {
  vertical-align: top;
  margin: 2vw;
  width: 28vw;
  flex: 0 0 28vw;
  display: inline-block;
  text-align: left;
}
.box-item img{
  width: 28vw;
  height: 28vw;
  margin: 2.8vw 0;
  display: block;
}
.box-item h4{
  font-size: 18px;
  font-weight: 700;
}
.box-item p{
  margin-top: 1vw;
  font-weight: lighter;
  line-height: 2rem;
}
</style>
